<template>
	<div id="app">
		<div v-for="(item, key) in $store.state.success" :key="key" @click="$store.state.success = []" class="globalMsg">
			<i class="fas fa-times" @click="$store.state.success = []"></i>
			<div v-html="item"></div>
		</div>

		<!-- <div @click="$store.state.success.push('test')">test</div> -->

		<header id="header">
			<div class="head">
				<router-link to="/" title="page d'accueil">
					<img src="./assets/img/logos/logo-main_r1_c1.png" alt="Logo Auto Parts Box" />
				</router-link>
				<div class="content">
					<p class="headRed"><i>LA SOLUTION ADAPTÉE</i></p>
					<p><i>AUX PROFESSIONNELS DE L'AUTOMOBILE</i></p>
				</div>
			</div>
			<nav class="headLinks">
				<router-link to="/"><i class="fas fa-home" title="page d'accueil"></i></router-link>
				<router-link to="/la-solution" title="la solution autopartsbox">LA SOLUTION</router-link>
				<router-link to="/les-fonctionnalites" title="les fonctionnalités">LES FONCTIONNALITÉS</router-link>
				<router-link to="/tarifs" title="Tarifs">TARIFS</router-link>
				<router-link to="/contact" title="Contactez-nous">CONTACT</router-link>
				<!-- <a @click="showLogPanel = !showLogPanel" class="headDemo" title="Essayez la solution autopartsbox">DEMO</a> -->
				<a href="http://demo.autopartsbox.fr/demo.html" class="headDemo" title="Essayez la solution autopartsbox">DÉMO</a>

				<a href="https://www.facebook.com/autopartsbox/" target="_blank" rel="noopener noreferrer"
					><i class="fab fa-facebook-f"></i
				></a>
				<a href="https://www.linkedin.com/showcase/autoparts-box/" target="_blank" rel="noopener noreferrer"
					><i class="fab fa-linkedin-in"></i
				></a>
			</nav>
			<div class="btnMenu">
				<i class="fad fa-bars" @click="paramMenu = !paramMenu"></i>
			</div>

			<div v-if="paramMenu" @click="paramMenu = !paramMenu" class="closableBg"></div>
			<!-- OU -->
			<nav class="menuMobile" :class="paramMenu ? 'showMenu' : 'hideMenu'">
				<i class="fad fa-times" @click="paramMenu = !paramMenu"></i>
				<div class="mobileLinks">
					<a :href="$router.resolve({ name: 'Home' }).href" title="Page d'accueil">ACCUEIL</a>
					<router-link to="/la-solution" title="La solution">LA SOLUTION</router-link>
					<router-link to="/les-fonctionnalites" title="Les fonctionnalités">LES FONCTIONNALITÉS</router-link>
					<router-link to="/tarifs" title="Les tarifs">TARIFS</router-link>
					<router-link to="/contact" title="Contactez nous">CONTACT</router-link>
					<a href="http://demo.autopartsbox.fr/demo.html" class="headDemo" title="Essayez la solution autopartsbox"
						>DÉMO</a
					>
					<!-- <a @click="showLogPanel = !showLogPanel" class="headDemo" title="Essayez la solution autopartsbox">DEMO</a> -->

					<div class="socialNet">
						<a href="https://www.facebook.com/autopartsbox/" target="_blank" rel="noopener noreferrer"
							><i class="fab fa-facebook-f"></i
						></a>
						<a href="https://www.linkedin.com/showcase/autoparts-box/" target="_blank" rel="noopener noreferrer"
							><i class="fab fa-linkedin-in"></i
						></a>
					</div>
				</div>
			</nav>
		</header>

		<transition name="fade">
			<router-view />
		</transition>

		<footer>
			<div class="container">
				<div class="block1 autopartsbox">
					<div class="title">
						<router-link to="/"
							><img src="./assets/img/logos/logo-footer_r1_c1.png" alt="Logo Auto Parts Box"
						/></router-link>
						<p><span>LA SOLUTION ADAPT&#201;E</span></p>
						<p class="footerRed">
							<span>AUX PROFESSIONNELS DE L'AUTOMOBILE</span>
						</p>
					</div>
					<div class="content">
						<p>
							Autopartsbox c'est un site de vente en ligne dédiée aux professionnels de l'automobile mais pas
							seulement ! Véritable outil de gestion, de conquête commercial, elle regroupe vos différents besoins
							au même endroit.
						</p>
					</div>
				</div>
				<div class="block1 services">
					<div class="title">
						<p @click="paramFonctionnalites = !paramFonctionnalites">
							<!-- Fonctionnalités -->
							Autoparts Box
							<i v-if="paramSupport == false" class="fad fa-chevron-down"></i>
							<i v-else class="fad fa-chevron-up"></i>
						</p>
					</div>
					<div class="content" :class="paramFonctionnalites ? 'showFonctionnalites' : 'hideFonctionnalites'">
						<router-link to="/la-solution">La solution</router-link>
						<router-link to="/les-fonctionnalites">Les fonctionnalités</router-link>
						<router-link to="/tarifs">Tarifs</router-link>
						<router-link to="/contact">Contact</router-link>
						<a href="http://demo.autopartsbox.fr/demo.html" title="Démo">Démo</a>
						<!-- <a @click="showLogPanel = !showLogPanel">Démo</a> -->
					</div>
				</div>
				<div class="block2 support">
					<div class="title">
						<p @click="paramSupport = !paramSupport">
							Support
							<i v-if="paramSupport == false" class="fad fa-chevron-down"></i>
							<i v-else class="fad fa-chevron-up"></i>
						</p>
					</div>
					<div class="content" :class="paramSupport ? 'showSupport' : 'hideSupport'">
						<router-link to="/la-solution">La solution</router-link>
						<router-link to="/contact">Contact</router-link>
						<!-- <a @click="showLogPanel = !showLogPanel">Démo</a> -->
						<a href="http://demo.autopartsbox.fr/demo.html" title="Démo">Démo</a>
						<router-link to="/tarifs">Tarifs</router-link>
					</div>
				</div>
				<div class="block2 socialNet">
					<div class="title">
						<p>Social</p>
					</div>
					<div class="content">
						<a href="https://www.facebook.com/autopartsbox/" target="_blank" rel="noopener noreferrer"
							><i class="fab fa-facebook-f"></i
						></a>
						<a href="https://www.linkedin.com/showcase/autoparts-box/" target="_blank" rel="noopener noreferrer"
							><i class="fab fa-linkedin-in"></i
						></a>
					</div>
				</div>
			</div>
			<div class="credits">
				<p class="cCred">&#169;</p>
				<a
					href="https://www.graphibox.biz/"
					target="_blank"
					rel="noopener noreferrer"
					title="Graphibox - agence digitale et hébergement mais pas que..."
					>Graphibox</a
				>
				<p class="reserve">| Tous droits réservés 2020 |</p>
				<router-link to="/mentions-legales" style="margin-left: 0.5rem">Mentions légales</router-link>
			</div>
		</footer>
		<LoginDemo v-if="showLogPanel" :open_close.sync="showLogPanel"></LoginDemo>

		<cookies color_two="#fff" color_one="#28455c" cookies_domain=".autopartsbox.fr" site="https://www.autopartsbox.fr/">
			<script async src="https://www.googletagmanager.com/gtag/js?id=UA-139015049-1" type="application/javascript"></script>
			<script type="application/javascript">
				window.dataLayer = window.dataLayer || [];
				function gtag() {
					dataLayer.push(arguments);
				}
				gtag("js", new Date());
				gtag("config", "UA-139015049-1");
			</script>
		</cookies>
	</div>
</template>

<script>
import LoginDemo from "@/components/LoginDemo.vue";
import cookies from "@/components/Cookies.vue";

export default {
	data() {
		return {
			paramMenu: false,
			paramFonctionnalites: false,
			paramSupport: false,
			showLogPanel: false,
		};
	},
	components: {
		LoginDemo,
		cookies,
	},
	created() {
		document.addEventListener("scroll", this.onScroll);
		new WOW({
			offset: 200,
		}).init();

		const urlParams = new URLSearchParams(window.location.search);
		const myParam = urlParams.get("err");
		if (myParam && myParam === "informations_invalides") {
			this.showLogPanel = true;
			this.$store.state.errors.push("<b style='font-size:1.5rem; color:red'>Vos identifiants sont invalides</b>");
		}
	},
	destroyed() {
		document.removeEventListener("scroll", this.onScroll);
	},
	mounted() {},
	methods: {
		onScroll() {
			let headEl = document.getElementById("header");

			if (window.scrollY >= 100) {
				headEl.classList.add("shrunkenHead");
			} else if (window.scrollY <= 50) {
				if (headEl.classList.contains("shrunkenHead")) {
					headEl.classList.remove("shrunkenHead");
				}
			}
		},
		selectSupport() {
			let c;
			if (this.paramSupport == false) {
				c = "hideSupport";
			} else {
				c = "showSupport";
			}
			return c;
		},
	},
};
</script>

<style>
@import url("./css/general.css");
@import url("./css/responsif.css");
@import url("./css/zythum.css");

:root {
	--color-1: #28455c;
	--color-11: #28455cbb;
	--color-2: #e3032b;
}
body {
	color: var(--color-1);
	font-family: Worksans;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: graphie, Verdana, sans-serif;
	font-weight: 800;
	font-style: normal;
}
a {
	cursor: pointer;
}
.closableBg {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
}
.wow {
	visibility: hidden;
}

.globalMsg {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 80%;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translate(-50%, -50%);
	background: white;
	box-shadow: 0 0 0px 1000px #000c;
	z-index: 10000;
	cursor: pointer;
	padding: 1rem;
}

.globalMsg > i {
	position: absolute;
	top: 8px;
	right: 8px;
	font-size: 1.2rem;
	cursor: pointer;
}
.globalMsg div {
	padding: 0.5rem;
	font-size: 1.2rem;
}

.fa-facebook-f,
.fa-linkedin-in,
.fa-twitter {
	border-radius: 100%;
	font-weight: 400;
}
.fa-facebook-f:hover,
.fa-linkedin-in:hover,
.fa-twitter:hover {
	border: 1px solid white;
	background-color: white;
}
.fa-facebook-f {
	border: 1px solid #3b5998;
	background-color: #3b5998;
	padding: 0.2rem 0.4rem;
}
.fa-facebook-f:hover {
	color: #3b5998;
}
.fa-linkedin-in {
	border: 1px solid #0e76a8;
	background-color: #0e76a8;
	padding: 0.2rem 0.3rem;
}
.fa-linkedin-in:hover {
	color: #0e76a8;
}
.fa-twitter {
	border: 1px solid #00acee;
	background-color: #00acee;
	padding: 0.2rem 0.2rem;
}
.fa-twitter:hover {
	color: #00acee;
}

/* HEADER */
header {
	position: fixed;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--color-1);
	background-color: rgba(255, 255, 255, 0.719);
	z-index: 2;
	width: 100%;
	box-shadow: 0px 2px 8px 0 #cccc;
	transition: 1s ease;
}
.shrunkenHead {
	background-color: rgba(255, 255, 255, 1);
	transition: 1s ease;
}

.shrunkenHead .head {
	height: 50px;
	padding: 10px 0;
}
.shrunkenHead .head img {
	height: 50px;
}
.shrunkenHead .head .content {
	margin-left: 0;
	transition: 1s ease;
}
header .head {
	width: 37%;
}
header .headLinks {
	width: 45%;
}
header .head {
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px 0;
	transition: 1s ease;
}
header .head a {
	width: 34%;
}
header .head img {
	width: 100%;
	transition: 1s ease;
	height: 80px;
	object-fit: contain;
}
header .head .content {
	margin-left: 2rem;
	text-align: left;
	font-size: 1.2rem;
	line-height: 20px;
	font-family: graphie, sans-serif;
	font-weight: 800;
	font-style: normal;
	/* font-family: Exo-Black; */
}
header .head .content .headRed {
	color: var(--color-2);
}
header .headLinks {
	display: flex;
	justify-content: space-around;
	align-items: center;
	font-weight: 600;
	padding-right: 2rem;
}
header .headLinks .fa-home,
header .headLinks a {
	color: var(--color-1);
}
header .headLinks a:hover .fa-home,
header .headLinks a:hover {
	color: var(--color-2);
}
header .headLinks a.router-link-exact-active i,
header .headLinks a.router-link-exact-active {
	color: var(--color-2);
}

header .headLinks .headDemo,
header .headLinks i {
	color: white;
}
header .headLinks .headDemo {
	border: 1px solid var(--color-2);
	padding: 0.4rem 1.5rem;
	background-color: var(--color-2);
	border-radius: 25px;
}
header .headLinks .headDemo:hover {
	background-color: white;
}

header .btnMenu,
header .menuMobile {
	display: none;
}

/* FOOTER */
footer {
	background-image: url("./assets/img/wallpapers/wallpaper-footer_r1_c1.png");
	background-color: var(--color-1);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	/* font-family: Exo; */
	font-family: Poppins, sans-serif;
	padding: 3rem 2rem 2rem 2rem;
}
footer .container {
	border-top: 1px solid var(--color-2);
	border-bottom: 1px solid white;
	width: 60%;
	margin: auto;
	padding: 5rem 1rem 2rem 1rem;
	display: flex;
	justify-content: space-between;
}
footer .container,
footer .container a {
	color: white;
}
footer .container .services .content,
footer .container .support .content {
	display: flex;
	flex-direction: column;
	line-height: 35px;
}
footer .container .socialNet .content {
	display: flex;
	justify-content: space-between;
	width: 75%;
}
footer .container .block1 {
	width: 25%;
}
footer .container .block2 {
	width: 10%;
}
footer .container .autopartsbox .title {
	/* font-family: Exo-Bold; */
	font-size: 0.7rem;
}
footer .container .autopartsbox .title img {
	width: 50%;
}
footer .container .autopartsbox .title .footerRed {
	color: var(--color-2);
}
footer .container .autopartsbox .content {
	margin-top: 1rem;
	line-height: 25px;
}
footer .container .services .title,
footer .container .support .title,
footer .container .socialNet .title {
	font-size: 1.2rem;
	margin: 1rem 0 2.5rem 0;
}
footer .container .services a:hover,
footer .container .support a:hover {
	color: var(--color-2);
}
footer .credits {
	/* border: 1px solid salmon; */
	display: flex;
	width: 100%;
	justify-content: center;
	margin: auto;
	margin-top: 3rem;
}
footer .credits,
footer .credits a {
	color: rgba(255, 255, 255, 0.493);
}
footer .credits a:hover {
	color: var(--color-2);
}
footer .credits .cCred {
	margin-right: 0.5rem;
}
footer .credits .reserve {
	margin-left: 0.5rem;
}
footer .fa-chevron-down,
footer .fa-chevron-up {
	visibility: hidden;
}

.solutionContainer {
	width: 60%;
	margin-left: 20%;
}
.fonctP {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.titleBox h2 {
	font-size: 3.2rem;
	margin-bottom: 1rem;
}

.titleBox h2 span {
	color: var(--color-2);
}

.fade-enter-active {
	transition: opacity 0.5s;
}
.fade-leave-active {
	display: none;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}

@media all and (max-width: 1000px) {
	.solutionContainer {
		width: 94%;
		padding: 1%;
		margin-left: 0;
	}
	.boxFonc {
		width: 90%;
		margin-left: 5%;
	}
}
@media all and (max-width: 768px) {
	.titleBox.boxFonc p {
		text-align: center;
	}
}
@media all and (max-width: 400px) {
	.titleBox h2 {
		font-size: 12vw;
	}
}
</style>
