<template>
  <div id="mentionslegales">
    <div style="height: 150px"></div>
    <section
      class="solutionContainer solutionP wow animate__fadeInUp"
      data-wow-duration="2s"
    >
      <h2><span>Mentions </span> légales / CGU</h2>
      <!-- <div class="Contener"> -->
      <div class="flex">
        <div
          class="wow fadeInRight side50 textColor1"
          data-wow-iteration="1"
          data-wow-duration=".6s"
        >
          <div style="text-align: justify">
            <div>
              <strong style="font-size: 1.2em">GRAPHIBOX agence web</strong
              ><br />
              Siège social :<br />
              <strong
                >GRAPHIBOX<br />
                9-A Rue des Tuileries<br />
                BP 37<br />
                32200 Gimont<br />
                France</strong
              ><br />
              <br />
              Téléphone: +33(0)5 62 67 88 20<br />
              Email: contact@graphibox.net<br />
              <br />
              Gérant: M. Franck DARTUS<br />
              Forme juridique: SARL unipersonnelle<br />
              SIRET: 43340577600033<br />
              CODE NAF: 6201Z<br />
              RCS: Auch B 433 405 776<br />
              Immatriculation: 08-11-2000<br />
              Nationalité: France<br />
              <br />
              <strong>Prestataire de formation</strong> :<br />
              - Numéro d'activité : <strong>73320052932</strong> ;<br />
              - Enregistré auprès du prefet de région Midi-Pyrénées.<br />
              <br />
            </div>
          </div>
        </div>
        <div
          class="wow fadeInLeft side50 textColor1"
          data-wow-iteration="1"
          data-wow-duration=".6s"
        >
          <div style="text-align: justify">
            <strong style="font-size: 1.2em"
              >Responsable rédaction &amp; publication</strong
            ><br />
            M. Franck DARTUS<br />
            <br />
            <strong style="font-size: 1.2em">Design &amp; conception</strong
            ><br />
            Graphibox.<br />
            <br />
            <strong style="font-size: 1.2em">Crédits photos</strong><br />
            Graphibox.<br />
            iStockphoto.<br />
            <br />
            <strong style="font-size: 1.2em"
              >Données personnelles - vie privée</strong
            ><br />
            En conformité avec les dispositions de la loi du 6 janvier 1978
            relative à l'informatique, aux fichiers et aux libertés, vous
            disposez d'un droit d'accès et de rectification portant sur les
            données vous concernant en écrivant au siège social.<br />
            <br />
            <br />
            <span
              ><a
                href="http://www.graphibox.biz"
                target="_blank"
                class="outLink"
                ><i class="fa fa-external-link" aria-hidden="true"></i>
                www.graphibox.biz</a
              ></span
            >
          </div>
        </div>
      </div>
      <!-- </div> -->
    </section>
    <section class="solutionContainer solutionP alignright">
      <h3>Politique de protection des données personnelles</h3>
      <br />
      <p>
        <strong
          >Conformément au règlement européen 2016/679 du 27 avril 2016 (dit «
          règlement général sur la protection des données » ou RGPD) </strong
        ><br />
        et directement applicable dans tous les états membre de l'UE le
        25/05/2018, vous pouvez à tout moment dans les conditions prévues par la
        loi, accéder à vos données personnelles, vous opposer pour motif
        légitime à leur traitement, les faire rectifier, demander leur
        effacement, la limitation de leur traitement ou leur portabilité.
        <br />
        Pour ce faire, il vous suffit d’écrire par email à :
        contact@graphibox.net ou par lettre simple à : 9-A Rue de la tuilerie<br />32200&nbsp;Gimont<br />Gers&nbsp;-&nbsp;France.<br />
        Les frais de timbre vous seront remboursés sur simple demande de votre
        part.
        <br />
        <br />
        <strong
          >Cette politique de protection des données à caractère personnel fixe
          la manière dont les données des utilisateurs sont traitées dans le
          cadre de ce site.
        </strong>
        <br />
        <br />
        Les utilisateurs du présent site sont susceptibles de communiquer, à
        GRAPHIBOX, éditeur du site, des données à caractère personnel les
        concernant en renseignant les formulaires ou questionnaires présents sur
        le site. Ces données pourront faire l'objet de traitement, automatisé ou
        non, au sens de la loi n°78-17 du 6 janvier 1978, dite loi Informatique
        et Libertés, dans les conditions attachées à chaque formulaire ou
        questionnaire.
        <br />
        <br />
        <strong>Responsable du traitement et finalités</strong><br />
        <br />

        Les données à caractère personnel collectées via les formulaires ou
        questionnaires figurant sur le présent site le sont par GRAPHIBOX
        éditeur du site, responsable du traitement.<br />
        <br />

        Dans le cadre du présent site, vos données à caractère personnel sont
        collectées aux fins de traitement de votre demande d'informations, de
        rappel, de rendez-vous ou de souscription ou de traitement statistiques,
        ou gestion de compte clients.<br />
        <br />

        <strong>Caractéristiques sur l'usage et la collecte de données</strong
        ><br />
        <br />

        Vous n'êtes pas obligé de répondre à tout ou partie des questions qui
        vous sont posées.<br />
        <br />

        Le caractère obligatoire de la communication des informations est
        indiqué par la présence d'un astérisque. Le défaut de communication de
        ces données aura pour conséquence l'impossibilité pour GRAPHIBOX de
        traiter votre demande d'informations, de rappel, de rendez-vous ou de
        souscription, ou gestion de compte clients.<br />
        <br />

        <strong>Modalités d'exercice de vos droits</strong><br />
        Vous pouvez, à tout moment, conformément à la loi, accéder aux
        informations vous concernant, les faire rectifier, s'opposer à leur
        utilisation à des fins commerciales, en adressant un courrier
        électronique à l'adresse suivante : contact@graphibox.net ou en écrivant
        par lettre simple à 9-A Rue de la tuilerie<br />32200&nbsp;Gimont<br />Gers&nbsp;-&nbsp;France.<br />
        <br />
        <strong>Transfert des données</strong><br /><br />
        Vos données à caractère personnel sont susceptibles d'être transférées
        et utilisation par GRAPHIBOX vers ses services internes, externes,
        partenaires. Pour connaître le transfert de vos données, contacté :
        contact@graphibox.net ou en écrivant par lettre simple à 9-A Rue de la
        tuilerie<br />32200&nbsp;Gimont<br />Gers&nbsp;-&nbsp;France<br /><br />
        <br />
        <br />
        <strong>Utilisation de vos données à des fins de prospection</strong
        ><br />
        Conformément à la réglementation applicable, toute prospection par
        courrier électronique ou fax, ainsi que toute cession ou location des
        données à des fins de prospection utilisant ces moyens, ne seront
        réalisées qu'avec le consentement préalable de l'utilisateur concerné à
        recevoir des prospections via ce canal ou/et à la cession de ses
        données.
        <br />
        Si vous acceptez de recevoir de tels messages de prospection en cochant
        les cases d'acceptation prévues à cet effet sur le présent site, il vous
        sera possible à tout moment de revenir sur ce consentement, et notamment
        chacun des courriers électroniques de prospection vous offrira la
        possibilité de vous opposer pour le futur à l'envoi de nouveaux
        courriers électroniques de prospection par GRAPHIBOX, éditeur du
        site.<br />
        <br />
        À tout moment, vous pouvez vous opposer à toute prospection par d'autres
        moyens en écrivant par courrier simple aux contacts mentionnés au
        paragraphe « Politique de protection des données ». &nbsp;Les frais de
        timbre seront remboursés sur simple demande.
        <br />
        <br />
        <strong>Cookies</strong><br />
        Nous souhaitons implanter un cookie dans votre ordinateur. Un cookie ne
        nous permet pas de vous identifier ; en revanche, il enregistre des
        informations relatives à la navigation de votre ordinateur sur notre
        site (les pages que vous avez consultées, la date et l'heure de la
        consultation, etc.) que nous pourrons lire lors de vos visites
        ultérieures. La durée de conservation de ces informations dans votre
        ordinateur est égale à 2 ans.<br />
        Nous vous informons que vous pouvez vous opposer à l'enregistrement de
        "cookies" en configurant votre navigateur.
      </p>
      <p>
        <br /><br />
        <strong>Sécurité</strong><br />
        <br />
        Notre préoccupation est de préserver la qualité et l'intégrité de vos
        données à caractère personnel. Les technologies et les politiques de
        sécurité appliquées permettent de protéger vos données à caractère
        personnel contre tout accès non autorisé, toute utilisation impropre.
        Les différentes personnes qui ont accès aux données sont tenues de
        respecter la vie privée des utilisateurs du présent site et la
        confidentialité de leurs données.
      </p>
    </section>
  </div>
</template>

<script>
import { titleMixin } from "../mixins/titleMixin";

export default {
  name: "Contact",
  mixins: [titleMixin],

  data() {
    return {
      title: "AutoParts Box - E-commerce, xRM pour grossiste et MRA pièce auto",
    };
  },
};
</script>

<style scoped>
#mentionslegales {
  background: url("../assets/img/wallpapers/wallpaper-jumbotron.png") no-repeat;
  background-attachment: fixed;
  background-size: contain;
  min-height: 80vh;
}
.solutionContainer {
  width: 1000px;
  margin: auto;
}
.solutionP {
  text-align: center;
  padding-bottom: 4rem;
}
.solutionP h2 {
  font-size: 3.2rem;
  margin-bottom: 2rem;
}
.solutionP h2 span {
  color: var(--color-2);
}

.flex {
  display: flex;
}
.flex > div {
  flex: 1;
}
.outLink {
  color: var(--color-1);
}
.alignright {
  text-align: left;
}
</style>