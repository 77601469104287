<template>
	<div id="LesFonctionnalites">
		<div style="height: 150px"></div>
		<section class="solutionContainer fonctP">
			<div class="boxFonc titleBox wow fadeyourself" data-wow-duration="1s">
				<i class="fal fa-tools"></i>
				<h2>Les <span>fonctionnalités </span></h2>
				<p>
					Autoparts Box regroupe des dizaines de fonctionnalités qui amélioreront votre quotidien et faciliteront toutes
					vos démarches (prises de commande, suivi des stocks, relation clients et fournisseurs…)
				</p>
			</div>

			<div class="boxFonc" id="ecommerce">
				<h3>
					<img src="@/assets/img/icones/shopping-cart.png" alt="autopartbox ecommerce" />
					E-commerce
				</h3>
				<p>
					Catalogue en ligne adapté en fonction du type de client qui se rend sur le site (professionnel ou
					particulier). Connecté avec votre ERP, vous pouvez associer un tarif à un client : ainsi suivant le client qui
					se connecte il aura un affichage personnalisé des différents produits avec les prix correspondants. Vous
					choisissez également les types de produits que vous souhaitez mettre en avant en fonction de vos offres du
					moment.
					<br />
					Solution rapide et connectée, vous connaissez en temps réel les différentes disponibilités des articles sur
					toutes les plateformes. Ainsi vous garantissez une réactivité dans la possibilité de commander une multitude
					de produits rapidement.
				</p>
			</div>

			<div class="boxFonc" id="paiementenligne">
				<h3>
					<img src="@/assets/img/icones/paiement.png" alt="autopartsbox Paiement en ligne sécurisé" />
					Paiement en ligne sécurisé
				</h3>
				<p>
					Permettez à vos clients de régler leurs achats directement sur votre site ! Vous pourrez également visualiser
					les historiques de paiement, consulter les statistiques des paniers moyens par client, les comportement
					d’achats etc…
				</p>
			</div>

			<div class="boxFonc" id="gestion">
				<h3>
					<img src="@/assets/img/icones/abacus.png" alt="autopartbox gestion ERP" />
					Gestion
				</h3>
				<p>
					Lié avec votre ERP, les conditions tarifaires de vos clients se mettent à jour automatiquement
					quotidiennement. Soyez informés des commandes de vos clients en ayant accès au suivi du process !
					<br />
					Mesurez la performance de vos clients en ayant accès à leur chiffre d’affaires, leur taux de visite, le nombre
					de commandes passées)
				</p>
			</div>

			<div class="boxFonc" id="publicite">
				<h3>
					<img src="@/assets/img/icones/sign.png" alt="autopartbox " />
					Publicité
				</h3>
				<p>
					Soyez les premiers sur Google ! Grâce à notre équipe technique nous vous garantissons un excellent
					référencement sur les moteurs de recherche : ainsi gagner en visibilité et faites la promotion de vos actions
					commerciales !
					<br />
					Restez en contact avec vos clients grâce au module tchat disponible sur votre site internet ! Vous pouvez
					également maintenir le lien en fidélisant votre clientèle grâce à l’outils d’envoi de mail (emailing)
					directement intégré à la solution et facile d’utilisation.
				</p>
			</div>

			<div class="boxFonc" id="technique">
				<h3>
					<img src="@/assets/img/icones/cogs.png" alt="autopartbox ecommerce" />
					Techniques
				</h3>
				<p>
					Offrez-vous une base de données multi produits !
					<br />
					Devenez libres de vendre des produits complémentaires. Nous pouvons intégrer des produits tels que les pièces
					auto avec l’outillage ou les pneumatiques par exemple.
					<br />
					Ouvrez le champ des possibles grâce à l’intégration des base de données produits EDI tiers (plateformes,
					équipementiers).
				</p>
			</div>

			<div class="boxFonc">
				<a href="http://demo.autopartsbox.fr/demo.html" class="bigRedBtn">Voir la démo</a>
			</div>
		</section>
	</div>
</template>

<script>
import { titleMixin } from "../mixins/titleMixin";

export default {
	name: "LesFonctionnalites",
	mixins: [titleMixin],

	data() {
		return {
			title: "AutoParts Box - E-commerce, xRM pour grossiste et MRA pièce auto",
		};
	},
};
</script>

<style scoped>
#LesFonctionnalites {
	background: url("../assets/img/wallpapers/wallpaper-jumbotron.png") no-repeat;
	background-attachment: fixed;
	background-size: contain;
	padding-bottom: 1rem;
}
.solutionContainer {
	width: 1000px;
	/* margin-left: 20%; */
	margin: auto;
	background: rgba(255, 255, 255, 0.5);
	padding: 0.5rem;
}
.fonctP {
	display: flex;
	flex-wrap: wrap;
	/* align-items: center; */
	justify-content: space-between;
}

.boxFonc {
	width: 45%;
	font-family: poppins, sans-serif;
	margin-bottom: 3rem;
}
.titleBox {
	text-align: center;
}
.titleBox h2 {
	font-size: 3.2rem;
	margin-bottom: 2rem;
}
.titleBox h2 span {
	color: var(--color-2);
}
.titleBox .fa-tools {
	font-size: 5rem;
	color: var(--color-1);
}
.titleBox.boxFonc p {
	font-size: 1.2rem;
	font-family: Worksans, sans-serif;
	font-weight: 700;
	text-align: left;
}
.fonctP .bigRedBtn {
	display: block;
	width: fit-content;
	margin: 2rem auto 3rem;
	text-transform: uppercase;
}

.boxFonc h3 {
	display: flex;
	align-items: center;
	color: var(--color-2);
	font-size: 1.5rem;
	font-weight: 700;
	margin-bottom: 1rem;
}
.boxFonc h3 img {
	height: 50px;
	margin-right: 1rem;
}
.boxFonc:not(.titleBox) p {
	font-weight: 500;
	font-size: 0.9rem;
	line-height: 1.2rem;
}

.fadeyourself {
	animation-name: fadeyourself;
}
@keyframes fadeyourself {
	0% {
		opacity: 1;
	}
	1% {
		opacity: 0;
		transform: scale(0.8) translateY(50%);
	}
}

@media all and (max-width: 1000px) {
	#LesFonctionnalites {
		background: none;
	}
	.solutionContainer {
		width: 94%;
		padding: 1%;
	}
	.boxFonc {
		width: 90%;
		margin-left: 5%;
	}
}
@media all and (max-width: 768px) {
	.titleBox.boxFonc p {
		text-align: center;
	}
}

@media all and (max-width: 400px) {
	.titleBox h2 {
		font-size: 12vw;
	}
}
</style>
