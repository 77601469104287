import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import Home from '../views/Home.vue';
import Contact from '../views/Contact.vue';
import LaSolution from '../views/LaSolution.vue';
import LesFonctionnalites from '../views/LesFonctionnalites.vue';
import Tarifs from '../views/Tarifs.vue';
import MentionsLegales from '../views/MentionsLegales.vue';

Vue.use(VueRouter);
Vue.use(Meta);

export default new VueRouter({
	mode: 'history',
	routes: [
		{
			path: '/',
			name: 'Home',
			component: Home,
		},
		{
			path: '/la-solution',
			name: 'LaSolution',
			component: LaSolution,
		},
		{
			path: '/les-fonctionnalites',
			name: 'LesFonctionnalites',
			component: LesFonctionnalites,
		},
		{
			path: '/tarifs',
			name: 'Tarifs',
			component: Tarifs,
		},
		{
			path: '/contact',
			name: 'Contact',
			component: Contact,
		},
		{
			path: '/mentions-legales',
			name: 'MentionsLegales',
			component: MentionsLegales,
		},
	],
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
});
