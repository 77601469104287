import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		VUE_APP_GRAPH_ID: 14,
		VUE_APP_GRAPH_APIKEY: 'VrPndaEzxehPS7f63ZEr8vVdK2HtGDfD',
		errors: [],
		success: [],
	},
	mutations: {},
	actions: {},
	modules: {},
});

export default store;
