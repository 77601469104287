<template>
  <div class="LoginDemo" @click.self="openClosePanel = false">
    <section>
      <i class="fas fa-times-circle" @click="openClosePanel = false"></i>

      <div class="inscription">
        <h3>S'inscrire</h3>

        <input type="text" placeholder="nom *" v-model="nom" required />
        <input type="text" placeholder="prenom *" v-model="prenom" required />
        <input type="text" placeholder="societe *" v-model="societe" required />
        <input type="text" placeholder="telephone *" v-model="tel" pattern="[0-9]{10}" required />
        <input type="mail" placeholder="Email * " v-model="email"
          pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" required />
        <br />
        <input type="submit" value="Je m'inscris !" class="bigRedBtn" @click="submitInscription()" />
      </div>

      <!--  -->
      <div>
        <h3>Se connecter</h3>

          <label for="login">Votre login </label>
          <input type="text" placeholder="Login " id="login" v-model="login" required />
          <label for="pass">Votre mot de passe </label>
          <input type="password" placeholder="mot de passe " id="pass" v-model="pass" required />
          <br />
          <input type="submit" value="Connexion à la démo" class="bigRedBtn" @click="submitLogin()" />
      </div>

      <div class="popupError" v-if="errors.length || success.length || this.$store.state.errors.length" 
      @click="errors = []; this.$store.state.errors = []">
        <div v-if="errors && errors.length || this.$store.state.errors.length" class="error">
          <ul>
            <li v-for="error in errors" :key="error" v-html="error"></li>
          </ul>
          <ul>
            <li v-for="error in  this.$store.state.errors" :key="error" v-html="error"></li>
          </ul>
        </div>
        <p v-if="success && success.length" class="success" @click="success = []">
        <ul>
          <li v-for="yes in success" :key="yes" v-html="yes"></li>
        </ul>
        </p>
      </div>


    </section>
  </div>
</template>

<script>

  import axios from 'axios'


  export default {
    name: "LoginDemo",

    data() {
      return {
        errors: [],
        success: [],
        //inscription
        nom: null,
        prenom: null,
        societe: null,
        tel: null,
        email: null,
        //   connexion
        login: null,
        pass: null,

      };
    },
    props: ["open_close"],
    computed: {
      openClosePanel: {
        get() {
          return this.open_close;
        },
        set(val) {
          return this.$emit("update:open_close", val);
        },
      },
    },

    methods: {
      submitInscription() {
        this.$store.state.errors = []
        if (
          !this.nom ||
          !this.prenom ||
          !this.societe ||
          !this.tel ||
          !this.email
        ) {
          this.errors = [];
          this.errors.push(
            "<b>Avant de passer à l'étape suivante, vous devez remplir les champs suivants :</b>"
          );
          if (!this.nom) {
            this.errors.push("Vous devez indiquer votre nom");
          }
          if (!this.prenom) {
            this.errors.push("Vous devez indiquer votre prénom");
          }
          if (!this.societe) {
            this.errors.push("Vous devez indiquer le nom de votre société");
          }
          if (!this.tel) {
            this.errors.push("Vous devez indiquer un numéro de téléphone");
          } else if (!this.phoneValidity()) {
            this.errors.push("Vous devez indiquer un numéro de téléphone valide");
          }
          if (!this.email) {
            this.errors.push("Vous devez indiquer un email");
          } else if (!this.emailValidity()) {
            this.errors.push("Vous devez indiquer un email valide");
          }
        } else {
          this.sendInscription()
          // this.success.push(
          //   "Vous êtes inscrit. Vous recevrez très prochainement un email avec vos identifiants de connexion."
          // );
          this.$store.state.success.push('Votre demande a bien été prise en compte. Nous vous recontacterons dans les meilleurs délais');
          this.$parent.showLogPanel = false;
        }
      },
      submitLogin() {
        this.$store.state.errors = []

        if (!this.login || !this.pass) {
          this.errors = [];
          this.errors.push("<b>Pour vous connecter vous devez d'abord </b>");
          if (!this.login) {
            this.errors.push("indiquer votre identifiant / login");
          }
          if (!this.pass) {
            this.errors.push("saisir votre mot de passe");
          }
        } else {
          this.sendLogin()
          // this.success.push(
          //   "Redirection en cours..."
          // );
        }
      },

      sendLogin() {
        this.post('/connexion.aspx', { 
          log: this.login,
          pass: this.pass,
          origin: window.location.href
        })
      },

      post(path, params) {
        const form = document.createElement('form');
        form.method = 'post';
        form.action = path;

        for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = key;
            hiddenField.value = params[key];

            form.appendChild(hiddenField);
          }
        }
        document.body.appendChild(form);
        form.submit();
      },


      sendInscription() {
        axios.post('/inscription.aspx', {
          name: this.nom,
          prenom: this.prenom,
          societe: this.societe,
          tel: this.tel,
          mail: this.email,
          origin: window.location.href
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });

      },


      emailValidity() {
        if (
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            this.email
          )
        ) {
          return true;
        }
        return false;
      },
      phoneValidity() {
        if (this.tel.match(/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/gim)) {
          return true;
        } else return false;
      },
    },
  };
</script>

<style scoped>
  .LoginDemo {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s ease;
  }

  @keyframes scaleOnOpen {
    from {
      transform: scale(0);
    }
  }

  .fa-times-circle {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2rem;
    cursor: pointer;
  }

  .LoginDemo>section {
    animation: scaleOnOpen 0.5s ease;
    background: white;
    width: calc(80% - 2rem);
    /* height: calc(80% - 2rem); */
    position: relative;
    display: flex;
    justify-content: space-around;
    padding: 2rem;
    flex-wrap: wrap;
  }

  .popupError {
    margin-top: 2rem;
  }

  .popupError ul {
    list-style: none;
  }

  .LoginDemo>section>div {
    width: 40%;
    display: flex;
    flex-direction: column;
  }

  .LoginDemo input {
    height: 35px;
    outline: none;
    margin: 0.5rem 0 0;
    padding: 0 8px;
    width: calc(100% - 16px);
    border-radius: 8px;
    border: 1px gray solid;
  }

  .LoginDemo input[type="submit"] {
    border: none;
    width: 50%;
    margin-left: 25%;
    text-transform: uppercase;
    font-size: 1.2rem;
  }

  .LoginDemo label {
    margin-top: 0.8rem;
    font-weight: bold;
  }

  .LoginDemo h3 {
    font-size: 1.7rem;
    text-transform: uppercase;
  }

  /* .inscription {
  display: flex;
  flex-direction: column;
} */

  @media all and (max-width: 1500px) {
    .LoginDemo input[type="submit"] {
      width: 70%;
      margin-left: 15%;
    }
  }

  @media all and (max-width: 1100px) {
    .LoginDemo input[type="submit"] {
      width: 90%;
      margin-left: 5%;
    }
  }

  @media all and (max-width: 900px) {
    .LoginDemo>section {
      flex-direction: column;
    }

    .LoginDemo>section>div {
      width: 100%;
      margin-bottom: 2rem;
    }
  }
</style>