<template>
  <div id="Tarifs">
    <div style="height: 150px"></div>
    <section class="solutionContainer fonctP">
      <div class="boxFonc titleBox">
        <h2>Les <span>tarifs </span></h2>
        <p>
          <b>Choisissez de rassembler tous vos outils au même endroit à partir de 950€/mois </b>
          <br />
          Nous sommes en partenariat avec des partenaires financiers pouvant
          vous accompagner dans le financement de l’offre.
        </p>
      </div>
    </section>
    <!-- PANNEAU ERREUR / REUSSITE -->
    <div class="errorpanel" v-if="errors.length || success.length" @click="errors = []">
      <div class="close">X</div>
      <div v-if="errors && errors.length" class="error">
        <ul>
          <li v-for="error in errors" :key="error" v-html="error"></li>
        </ul>
      </div>
      <p v-if="success && success.length" class="success" @click="success = []">
      <ul>
        <li v-for="yes in success" :key="yes" v-html="yes"></li>
      </ul>
      </p>
    </div>
    <!-- LE FORMULAIRE -->
    <transition name="formAnim">
      <section class="form  step step1" v-if="step == 0" key='step0'>
        <div class="input1 LEFT">
          <label for="ermSelect">Votre
            <span style="text-decoration: underline dotted"
              title="Un ERP (Enterprise Resource Planning) ou encore parfois appelé PGI (Progiciel de Gestion Intégré).">
              ERP ?
            </span>
            *
          </label>
          <br />
          <br />
          <select name="ermSelect" id="ermSelect" v-model="usedERP">
            <option value="">--- Choisissez votre ERP ---</option>
            <option value="star6000">star6000 ( STARIS )</option>
            <option value="Inovaxo">Inovaxo </option>
            <option value="Agra">Agrasoft</option>
            <option value="Acrom">Acrom</option>
            <option value="GPA">GPA</option>
            <option value="Autres">Autres</option>
            <option value="Je ne sais pas">Je ne sais pas</option>
          </select>
        </div>

        <div class="checkBoxs CENTER">
          <div class="label">Quels sont vos besoins ?*</div>
          <br />
          <article>
            <label for="venteEnLigne">
              <span class="check_style">
                <input type="checkbox" name="venteEnLigne" id="venteEnLigne" value="vente en ligne" v-model="besoins" />
                <span class="themark"></span>
              </span>
              <span class="desc">Vendre en ligne</span>
            </label>
            <label for="fidele">
              <span class="check_style">
                <input type="checkbox" name="fidele" id="fidele" value="Fidéliser ma clientèle" v-model="besoins" />
                <span class="themark"></span>
              </span>
              <span class="desc"> Fidéliser ma clientèle</span>
            </label>

            <label for="desengorge">
              <span class="check_style">
                <input type="checkbox" name="desengorge" id="desengorge" value="Désengorgez mes comptoirs"
                  v-model="besoins" />
                <span class="themark"></span>
              </span>
              <span class="desc"> Désengorgez mes comptoirs</span>
            </label>

            <label for="rassemble" class="check_style">
              <span class="check_style">
                <input type="checkbox" name="rassemble" id="rassemble" value="Rassembler mes outils"
                  v-model="besoins" />
                <span class="themark"></span>
              </span>
              <span class="desc">Rassembler mes outils</span>
            </label>

            <label for="choix_plateforme" class="check_style">
              <span class="check_style">
                <input type="checkbox" name="choix_plateforme" id="choix_plateforme"
                  value="Choisir différents produits de différentes plateformes" v-model="besoins" />
                <span class="themark"></span>
              </span>
              <span class="desc">Choisir différents produits de différentes plateformes</span>
            </label>

            <label for="autres">
              <span class="check_style">
                <input type="checkbox" name="autres" id="autres" value="Autres" v-model="besoins" />
                <span class="themark"></span>
              </span>
              <span class="desc">Autres</span>
            </label>
          </article>
        </div>

        <div class="RIGHT inputRadio ">
          <div class="label">Combien avez-vous de salariés ?*</div>
          <br />
          <label for="oneFive">
            <span class="radio_style">
              <input type="radio" id="oneFive" name="drone" value="entre 1 et 5" v-model="nbrSalaries" />
              <span class="checkmark"></span>
            </span>
            <span class="desc"> De 1 à 5</span>
          </label>
          <br />
          <br />
          <label for="five20">
            <span class="radio_style">
              <input type="radio" id="five20" name="drone" value="entre 5 et 20" v-model="nbrSalaries" />
              <span class="checkmark"></span>
            </span>
            <span class="desc">De 5 à 20</span>
          </label>
          <br />
          <br />
          <label for="twenty50">
            <span class="radio_style">
              <input type="radio" id="twenty50" name="drone" value="entre 20 et 50" v-model="nbrSalaries" />
              <span class="checkmark"></span>
            </span>
            <span class="desc">De 20 à 50</span>
          </label>
          <br />
          <br />
          <label for="fiftyMore">
            <span class="radio_style">
              <input type="radio" id="fiftyMore" name="drone" value="50 et +" v-model="nbrSalaries" />
              <span class="checkmark"></span>
            </span>
            <span class="desc">50 et +</span>
          </label>
        </div>

        <section class="formFooter">
          <div class="stepIndicator">Étape <b><span>{{ step + 1}} </span>/{{ nbrOfSteps }}</b></div>
          <a href="javascript:;" @click=" validationFirstStep" class="bigRedBtn">
            Étape suivante
          </a>
        </section>
      </section>
      <!--  -->
      <section class="form step step2" v-if="step == 1" key='step1'>
        <div class="backDiv">
          <a @click="step--"> <i class="fas fa-arrow-left "></i> Retour étape 1</a>
        </div>

        <div class="LEFT">
          <div class="input1">
            <label for="entreprise">Quel est votre entreprise ?*</label>
            <input type="text" id="entreprise" v-model="entreprise" />
          </div>
          <div class="input1">
            <label for="codepostal">Code postal</label>
            <input type="text" id="codepostal" pattern="[0-9]{5}" v-model="codepostal" />
          </div>
          <div class="input1">
            <label for="telephone">Téléphone*</label>
            <input type="phone" id="telephone" pattern="[0-9]{10}" v-model="telephone" />
          </div>
        </div>

        <div class="CENTER">
          <div class="input1">
            <label for="prenom">Nom et prénom *</label>
            <input type="text" id="prenom" v-model="fullname" />
          </div>

          <div class="input1">
            <label for="email">Email*</label>
            <input type="mail" id="email" v-model="email" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
              required />
          </div>
        </div>

        <div class="RIGHT">
          <div class="inputRadio ">
            <div class="label">Quelle est votre activité ?*</div>
            <br />
            <label for="grossiste">
              <span class="radio_style">
                <input type="radio" id="grossiste" name="grossiste" value="Grossiste" v-model="activite" />
                <span class="checkmark"></span>
              </span>
              <span class="desc">Grossiste</span>
            </label>
            <br />
            <label for="plateforme">
              <span class="radio_style">
                <input type="radio" id="plateforme" name="plateforme" value="Plateforme - distribution"
                  v-model="activite" />
                <span class="checkmark"></span>
              </span>
              <span class="desc">Plateforme - distribution</span>
            </label>
            <br />
            <label for="garage">
              <span class="radio_style">
                <input type="radio" id="garage" name="garage" value="Garages" v-model="activite" />
                <span class="checkmark"></span>
              </span>
              <span class="desc">Garage</span>
            </label>
            <br />
            <label for="mra">
              <span class="radio_style">
                <input type="radio" id="mra" name="mra" value="MRA" v-model="activite" />
                <span class="checkmark"></span>
              </span>
              <span class="desc">MRA</span>
            </label>
            <br />
            <label for="Groupement">
              <span class="radio_style">
                <input type="radio" id="Groupement" name="Groupement" value="Groupement" v-model="activite" />
                <span class="checkmark"></span>
              </span>
              <span class="desc">Groupement</span>
            </label>
            <br />
            <label for="autres_act">
              <span class="radio_style">
                <input type="radio" id="autres_act" name="autres_act" value="Autres" v-model="activite" />
                <span class="checkmark"></span>
              </span>
              <span class="desc">Autres</span>
            </label>
          </div>
        </div>
        <textarea placeholder="Un commentaire, une question ? " v-model="message"></textarea>
        <section>
          <label for="conditionUtilisation" class="check_style">
            <span class="check_style">
              <input type="checkbox" name="conditionUtilisation" id="conditionUtilisation"
                v-model="acceptConditionUse" />
              <span class="themark"></span>
            </span>
            <span style="margin-left: 2rem">J'accepte les conditions d'utilisation *</span>
          </label>
        </section>

        <section class="formFooter">
          <div class="stepIndicator">Étape <b><span>{{ step + 1}} </span>/{{ nbrOfSteps }}</b>
          </div>
          <a href="javascript:;" @click="checkForm()" class="bigRedBtn">Envoyer</a>

        </section>
      </section>

    </transition>

    <section class="solutionContainer fonctP">
      <a href="javascript:;" class="conditionLink">Les champs suivis d’un * sont obligatoires.</a>
    </section>

    <br /><br />
    <NosPartenaires></NosPartenaires>
    <br /><br />

  </div>
</template>

<script>
  import { titleMixin } from "../mixins/titleMixin";
  import NosPartenaires from "@/components/NosPartenaires.vue";
  import axios from 'axios'

  export default {
    name: "Tarifs",
    mixins: [titleMixin],
    components: {
      NosPartenaires,
    },
    data() {
      return {
        title: "AutoParts Box - E-commerce, xRM pour grossiste et MRA pièce auto",
        errors: [],
        success: [],
        step: 0,
        nbrOfSteps: 2,
        //
        activite: [],
        using_erp: false,
        usedERP: null,
        nbrSalaries: null,
        besoins: [],
        entreprise: null,
        codepostal: null,
        telephone: null,
        fullname: null,
        // nom: null,
        // prenom: null,
        email: null,
        acceptConditionUse: false,
        message: null,
        //
        // ip: "string",
        // browser: "string",
        timer: null,
        time: 0,
      };
    },
    mounted() {
      this.timer = window.setInterval(() => {
        this.time++;
      }, 1000);

    },
    beforeDestroy() {
      if (this.timer) {
        window.clearInterval(this.timer);
        this.timer = null;
      }
    },

    methods: {

      emailIsvalid() {
        if (
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            this.email
          )
        ) {
          return true;
        }
        return false;
      },
      phoneValidity() {
        if (
          this.telephone.match(
            /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/gim
          )
        ) {
          return true;
        } else return false;
      },
      checkForm: function () {
        // if required fields are set
        if (
          this.fullname &&
          this.email &&
          this.emailIsvalid() &&
          this.acceptConditionUse &&
          this.telephone &&
          this.phoneValidity() &&
          this.nbrSalaries &&
          this.entreprise &&
          this.besoins.length
        ) {
          let value = this.prepareMail();
          this.sendMail(value);
          // this.success.push('Votre demande a bien été prise en compte. Nous vous recontacterons dans les meilleurs délais');
          this.$store.state.success.push('Votre demande a bien été prise en compte. Nous vous recontacterons dans les meilleurs délais');
          return true;
        } else if (this.faxNumber || this.time < 12) {
          this.errors = [];
          this.success.push("Mail bien envoyé");
        } else {
          this.errors = [];
          this.errors.push(
            "<b>Avant d'envoyer votre demande, vous devez remplir les champs suivants :</b>"
          );
          if (!this.fullname) {
            this.errors.push(" Nom , prénom");
          }
          if (!this.email) {
            this.errors.push("Email ");
          }
          if (!this.emailIsvalid()) {
            this.errors.push("Email invalide");
          }
          if (!this.telephone) {
            this.errors.push("Téléphone requis");
          }
          if (!this.phoneValidity()) {
            this.errors.push("Le numéro de téléphone n'est pas valide");
          }
          if (!this.acceptConditionUse) {
            this.errors.push(
              "Vous devez accepter les conditions d'utilisations."
            );
          }
          if (!this.nbrSalaries) {
            this.errors.push("Vous devez indiquer le nombre de salariés");
          }
          if (!this.entreprise) {
            this.errors.push("Vous devez indiquer le nom de votre entreprise");
          }
          if (!this.activite) {
            this.errors.push(
              "Vous devez indiquer l'activité de votre entreprise"
            );
          }
          if (!this.usedERP) {
            this.errors.push("Vous devez indiquer un choix pour votre ERP");
          }
        }
      },
      validationFirstStep() {
        if (!this.nbrSalaries && !this.besoins.length && !this.using_erp) {
          this.errors = [];
          this.errors.push(
            "<b>Avant de passer à l'étape suivante, vous devez remplir les champs suivants </b>"
          );
          if (!this.nbrSalaries) {
            this.errors.push("Vous devez indiquer le nombre de salariés");
          }
          if (!this.usedERP) {
            this.errors.push("Vous devez indiquer un choix pour votre ERP");
          }
          if (!this.besoins.length) {
            this.errors.push("Vous devez indiquer au moins un de vos besoins");
          }
        } else {
          this.step++;
        }
      },

      prepareMail() {
        let val = "Nom/prénom : " + this.fullname + "|||";
        val += "Email : " + this.email + "|||";
        val += "Tel : " + this.telephone + "|||";
        val += "Code postal : " + this.codepostal + "|||";
        val += "Nom de l'entreprise : " + this.entreprise + "|||";
        val += "Activité de l'entreprise : " + this.activite + "|||";
        val += "Nombre de salariés : " + this.nbrSalaries + "|||";
        val += "ERP utilisé : " + this.usedERP + "|||";
        val += "Quels sont vos besoins ? " + "|||";
        for (let i = 0; i < this.besoins.length; i++) {
          val += "- " + this.besoins[i] + "|||";
        }
        if (this.message) {
          val += "Message :  " + this.message;
        }
        return val;
      },

      sendMail(message) {
        axios.post('/contact.aspx', {
          contact: this.email,
          nom: this.fullname,
          tel: this.telephone,
          zipcode: this.codepostal,
          entreprise: this.entreprise,
          activite: this.activite,
          nbrSalaries: this.nbrSalaries,
          usedERP: this.usedERP,
          needs: JSON.stringify(this.besoins),
          message: this.message,
          origin: window.location.href,
          fullMsg: message, 
        },
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          })
          .then( (response) =>{
            console.log(response);
            console.log("this is success");
            this.resetForm()
            setTimeout(() => {
              this.$router.push('/')
            }, 1000);
          })
          .catch(function (error) {
            console.log(error);
          });
      },
       resetForm() {
          this.activite =  []
          this.using_erp =  false
          this.usedERP =  null
          this.nbrSalaries =  null
          this.besoins =  []
          this.entreprise =  null
          this.codepostal =  null
          this.telephone =  null
          this.fullname =  null
          this.email =  null
          this.acceptConditionUse =  false
          this.message =  null
       }
    },

  };
</script>

<style scoped>
  .step {
    display: flex;
  }
  .step>* {
    flex: 1;
  }
  .step {
    flex-wrap: wrap;
  }
  .step2 textarea {
    margin-top: 1.5rem;
    padding: 1rem;
    width: calc(100% - 2rem);
    flex: unset;
    border: none;
    border-radius: 8px;
    height: 120px;
    font-family: inherit;
    outline: none;
  }

  .formFooter {
    width: 100%;
    flex: unset;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
  }

  .formFooter>* {
    flex: unset;
  }

  .formFooter .bigRedBtn {
    margin-top: 0;
    width: 150px;
    flex: unset;
    margin: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  .stepIndicator {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .stepIndicator span {
    font-size: 2.2rem;
    color: var(--color-2);
    margin: 0 -2px;
  }

  .stepIndicator b {
    font-size: 1.5rem;
  }

  .backDiv {
    width: 100%;
    flex: unset;
    margin-bottom: 1rem;
  }

  .backDiv a {
    cursor: pointer;
  }

  /* .validationFirstStep {

} */
  form textarea:focus:invalid,
  input:focus:invalid {
    border: 1px solid tomato;
    box-shadow: 0 0 5px tomato;
  }

  textarea:focus:valid,
  input:focus:valid {
    border: 1px solid #8acb23;
    box-shadow: 0 0 5px #8acb23;
  }

  option {
    font-size: 1.1rem;
    color: var(--color-1);
  }

  #Tarifs {
    background: url("../assets/img/wallpapers/wallpaper-jumbotron.png") no-repeat;
    background-attachment: fixed;
    background-size: contain;
    position: relative;
  }

  #ermSelect {
    text-transform: uppercase;
  }

  .errorpanel {
    background: white;
    position: fixed;
    top: 25%;
    left: 5%;
    right: 5%;
    z-index: 10;
    box-shadow: 0 0 8px 0 #bbb;
    text-align: center;
  }

  .errorpanel ul {
    list-style: none;
  }

  .errorpanel .error {
    padding: 1rem;
  }

  .errorpanel .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-weight: bold;
  }

  .errorpanel .success {
    padding: 1rem;
  }

  .RIGHT .label,
  .RIGHT label,
  .RIGHT input,
  .RIGHT select {
    margin-left: 20%;
  }

  .CENTER>* {
    margin-left: 10%;
  }

  .boxFonc {
    font-family: poppins, sans-serif;
    margin-bottom: 3rem;
  }

  .fonctP .bigRedBtn {
    display: block;
    width: fit-content;
    margin: 2rem auto 3rem;
    text-transform: uppercase;
  }

  .conditionLink {
    color: #333;
  }

  .form {
    background: #e6e9ec;
    padding: 2rem;
    width: 1100px;
    margin: auto;
    margin-bottom: 1rem;
  }

  .form>section {
    width: 100%;
    display: flex;
    margin-top: 2rem;
  }

  .form>section:first-child {
    margin-top: 0;
  }

  .form>section>* {
    flex: 1;
  }

  .input1 {
    margin-bottom: 1rem;
  }

  .input1 input,
  .input1 select {
    margin-top: 4px;
    height: 40px;
    border: none;
    outline: none;
    border-radius: 16px;
    padding: 0 8px;
    width: 80%;
    display: block;
  }

  .input1 select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: white url("../assets/img/icones/arrow_input.png") no-repeat;
    background-position: 95% center;
  }

  .form .label,
  .form label {
    font-family: poppins, sans-serif;
    font-weight: 600;
  }

  .inputToggle {
    text-align: center;
  }

  .inputToggle>div {
    margin-top: 4px;
  }

  input[type="radio"] {
    margin-right: 1rem;
  }

  .checkBoxs article {
    background: white;
    border-radius: 8px;
    width: calc(90% - 2rem);
    padding: 1rem;
  }

  .checkBoxs label {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    height: 40px;
  }

  /* DEBUT BTN TOGGLE */
  .switch {
    position: relative;
    display: inline-block;
    width: 58px;
    height: 28px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .toggleBtn {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .toggleBtn:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 2px;
    bottom: 2px;
    background-color: var(--color-2);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked+.toggleBtn:before {
    transform: translateX(30px);
    background-color: white;
  }

  input:checked+.toggleBtn {
    background-color: var(--color-2);
  }

  .toggleBtn.round {
    border-radius: 30px;
  }

  .toggleBtn.round:before {
    border-radius: 50%;
  }

  .switch em {
    position: absolute;
    z-index: 1;
    top: 4px;
    color: white;
    font-size: 0.9rem;
    pointer-events: none;
    transition: 0.3s ease;
  }

  .switch em.yes {
    left: 4px;
  }

  .switch em.no {
    right: 4px;
    color: #fff;
  }

  /* FIN BTN TOGGLE */
  /* RADIO */
  .radio_style {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
  }

  .radio_style input {
    position: relative;
    opacity: 0;
    cursor: pointer;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 21px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #ccc;
  }

  .radio_style:hover input~.checkmark {
    background-color: #ccc;
  }

  .radio_style input:checked~.checkmark {
    background-color: white;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .radio_style input:checked~.checkmark:after {
    display: block;
  }

  .radio_style .checkmark:after {
    top: 2px;
    left: 2px;
    width: 18px;
    height: 17px;
    border-radius: 50%;
    background: var(--color-2);
  }

  .inputRadio .desc {
    margin-left: 1rem;
  }

  .inputRadio label {
    display: flex;
    align-items: center;
  }

  /* FIN RADIO */

  /* CHECKBOX */
  /* Customize the label (the container) */
  .checkBoxs label {
    position: relative;
    cursor: pointer;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .check_style {
    position: relative;
    height: 20px;
  }

  .check_style input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 20px;
    /* margin-right: 2rem; */
  }

  .themark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border: 1px #333 solid;
  }

  .check_style:hover input~.themark {
    background-color: #ccc;
  }

  .check_style input:checked~.themark {
    /* background-color: var(--color-2); */
    border: 1px var(--color-2) solid;
  }

  .themark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .check_style input:checked~.themark:after {
    display: block;
  }

  .check_style .themark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid var(--color-2);
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  .checkBoxs .desc {
    margin-left: 2rem;
  }

  /* FIN CHECKBOX */

  @media all and (max-width: 1250px) {
    .form {
      width: 900px;
    }
  }

  @media all and (max-width: 1000px) {
    .form {
      width: calc(100% - 4rem);
      flex-direction: column;
    }

    .form>section {
      flex-wrap: wrap;
    }

    .form>section>* {
      flex: unset;
      width: 100%;
    }

    .RIGHT label,
    .RIGHT input,
    .RIGHT select {
      margin-left: 0;
    }

    .CENTER>* {
      margin-left: 0;
    }

    .input1 input,
    .input1 select {
      width: 100%;
    }

    .inputRadio {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 1rem;
    }

    .inputRadio .label {
      width: 100%;
    }

    .inputRadio label {
      margin: 0 1rem;
    }

    .checkBoxs {
      margin-bottom: 1rem;
    }

    .checkBoxs article {
      width: calc(100% - 2rem);
    }
  }

  .formAnim-enter-active {
    transition: opacity 0.5s;
  }
  .formAnim-leave-active {
    display: none;
  }

  .formAnim-enter {
    opacity: 0;
  }
  .formAnim-leave-to {
    display: none;
  }
</style>