<template>
  <div id="contact">
    <div style="height: 150px"></div>
    <section class="solutionContainer fonctP">
      <div class="titleBox">
        <h2>Contactez-<span>nous </span></h2>
      </div>

      <p>
        Acteur du secteur de l’automobile depuis 2008, Autopartsbox.fr possède
        une équipe de professionnels qui se tient à votre disposition pour
        répondre à toutes vos questions !
      </p>
      <div class="flex phoneMail spaceBetween full wrap">
        <div>
          <a class="flex middleAlign" href="tel:+33562678820">
            <i class="fad fa-phone-rotary fa-3x me"></i>+33 (0)5 62 67 88 20
          </a>
        </div>
        <div>
          <a
            href="mailto:info@autopartsbox.fr"
            class="flex middleAlign"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fad fa-envelope fa-3x me"></i>
            info@autopartsbox.fr
          </a>
        </div>
      </div>
    </section>
    <!-- Formulaire -->
  </div>
</template>

<script>
import { titleMixin } from "../mixins/titleMixin";

export default {
  name: "Contact",
  mixins: [titleMixin],

  data() {
    return {
      title: "AutoParts Box - E-commerce, xRM pour grossiste et MRA pièce auto",
    };
  },
};
</script>

<style scoped>
.titleBox {
  width: 100%;
  text-align: center;
}

.fonctP p {
  font-weight: bold;
  font-size: 1.1rem;
  text-align: center;
  margin-bottom: 1rem;
}
.phoneMail a,
.phoneMail p {
  font-size: 1.7rem;
  font-weight: bold;
  color: var(--color-2);
}
.phoneMail i {
  color: var(--color-1);
}

.phoneMail {
  margin: 2rem 0;
}

@media all and (max-width: 700px) {
  .phoneMail a,
  .phoneMail p {
    font-size: 1.3rem;
    font-weight: bold;
    color: var(--color-2);
  }
}
@media all and (max-width: 400px) {
  .phoneMail i {
    font-size: 2em;
  }
}
</style>