<template>
	<div class="NosPartenaires">
		<!-- Bloc partenaires -->
		<div class="partenaires">
			<p>Ils nous ont fait confiance</p>
			<div class="Container">
				<div class="Content wow animate__fadeInUp" data-wow-duration="1s">
					<a href="https://www.aaiweb.fr/" target="_blank" rel="noopener noreferrer">
						<img src="../assets/img/partenaires/logo_AAI.png" alt="Logo Alliance Auto Industrie" />
					</a>
				</div>
				<!-- <div class="Content wow animate__fadeInUp" data-wow-duration="1.5s">
					<a href="https://www.h-piecesauto.fr/" target="_blank" rel="noopener noreferrer">
						<img src="../assets/img/partenaires/logo_Hautot.png" alt="Logo Hautot et fils" />
					</a>
        </div> -->

				<div class="Content wow animate__fadeInUp" data-wow-duration="2s">
					<a href="https://www.otorep.fr/" target="_blank" rel="noopener noreferrer">
						<img src="../assets/img/partenaires/otorep.jpg" alt="Logo Polaire" />
					</a>
				</div>

				<div class="Content wow animate__fadeInUp" data-wow-duration="2s">
					<a href="https://www.polaire.eu/" target="_blank" rel="noopener noreferrer">
						<img src="../assets/img/partenaires/Logo_Polaire_degrade.png" alt="Logo Polaire" />
					</a>
				</div>
				<!-- <div class="Content wow animate__fadeInUp" data-wow-duration="2.5s">
          <a
            href="https://apa-trading.fr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="../assets/img/partenaires/apa-logo.png"
              alt="Logo APA trading"
            />
          </a>
        </div> -->
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NosPartenaires',
};
</script>

<style scoped>
.partenaires {
	width: 100%;
	background-color: #ecebec;
	padding: 2rem 0;
	color: var(--color-1);
}
.partenaires p {
	text-align: center;
	font-size: 3rem;
	font-weight: 650;
}
.partenaires .Container {
	width: 65%;
	margin: auto;
	margin-top: 0.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.partenaires .Container .Content {
	width: 20%;
}
.partenaires .Container .Content img {
	width: 100%;
	transition: all 0.3s ease-out;
}
.partenaires .Container .Content img:hover {
	filter: drop-shadow(16px 16px 20px red) invert(75%);
}
</style>
