<template>
	<div id="LaSolution">
		<div style="height: 150px"></div>
		<section class="solutionContainer solutionP wow animate__fadeInUp" data-wow-duration="2s">
			<i class="fal fa-toolbox"></i>
			<h2><span>La </span> solution</h2>

			<p>
				Autopartsbox.fr c’est un site de vente en ligne dédié aux professionnels de l’automobile mais pas seulement !
				<br />
				Véritable outil de gestion et de conquête commerciale, elle regroupe vos différents besoins au même endroit.
				<br /><br />
				Optimisez votre temps grâce à cette solution web, créée par des professionnels du secteur de l’automobile afin de
				répondre au mieux à vos problématiques quotidiennes et vous permettre d’accroître votre business en augmentant
				votre visibilité sur Google.
				<br /><br />
				Polyvalente, elle s’adapte et se connecte à votre propre logiciel de gestion ou tout autre logiciel déjà existant
				;<br />
				(Acrom, Sage, Innovaxo , Star6000 (STARIS), Agrasoft, GPA).
				<br />
				<br />
				Ainsi, en plus de proposer un catalogue en ligne de vos produits vous pouvez adapter l’offre en fonction de votre
				client.
			</p>

			<a @click="$parent.showLogPanel = !$parent.showLogPanel" class="bigRedBtn">Voir la démo</a>
		</section>

		<section class="bandeau bandeauBeige">
			<section class="solutionContainer">
				<div class="flex1 txtBox">
					<h3 class="wow animate__fadeInUp" data-wow-duration="2s">
						Solution <br />
						<span>
							complète <br />
							sur-mesure <br />
							et évolutive
						</span>
					</h3>
				</div>
				<div class="flex1 imgBox">
					<img
						class="wow scaleX"
						data-wow-duration="2s"
						src="@/assets/img/solution_page/autopartsbox_4.jpg"
						alt="module a remplacer"
					/>
				</div>
			</section>
		</section>

		<section class="bandeau">
			<section class="solutionContainer">
				<div class="flex3 imgBox">
					<img
						class="wow scaleX"
						data-wow-duration="2s"
						src="@/assets/img/solution_page/autopartsbox_3.jpg"
						alt="module a remplacer"
					/>
				</div>

				<div class="flex2 txtBox flexColumn">
					<h3 class="wow animate__fadeInUp" data-wow-duration="2s">
						S'adapte <br />
						à tous les <br />
						<span> ERP </span>
					</h3>
					<br />
					<a
						href="http://www.artinformatique.net/"
						alt="art informatique"
						target="_blank"
						rel="noopener noreferrer"
						class="flex middleAlign "
					>
						<span style="font-size:2em;font-weight:bold" class="me">GPA</span>
						<img src="@/assets/img/logos/artinformatique.png" alt="art informatique" style="height:71px!important;" />
					</a>
				</div>
			</section>
		</section>

		<section class="bandeau bandeauBeige">
			<section class="solutionContainer">
				<div class="flex1 txtBox">
					<h3 class="wow animate__fadeInUp" data-wow-duration="2s">
						Fidélisez vos MRA <br />
						en leur proposant <br />
						une solution intuitive, <br />
						facile d'utilisation <br />
						et disponible <br />
						<span> 24h/24 7j/7 </span>
					</h3>
				</div>
				<div class="flex1 imgBox">
					<img
						class="wow scaleX"
						data-wow-duration="2s"
						src="@/assets/img/solution_page/autopartsbox_2.jpg"
						alt="module a remplacer"
					/>
				</div>
			</section>
		</section>

		<section class="bandeau">
			<section class="solutionContainer">
				<div class="flex3 imgBox">
					<img
						class="wow scaleX"
						data-wow-duration="2s"
						src="@/assets/img/solution_page/autopartsbox_1.jpg"
						alt="module a remplacer"
					/>
				</div>

				<div class="flex2 txtBox">
					<h3 class="wow animate__fadeInUp" data-wow-duration="2s">
						Désengorgez vos <br />
						comptoirs : <br />
						vos clients peuvent <br />
						commander <br />
						directement <br />
						depuis leur ordinateur <br />
						<span>
							Livraison chez le client <br />
							ou drive (click and collect) chez vous
						</span>
					</h3>
				</div>
			</section>
		</section>

		<NosPartenaires></NosPartenaires>
		<br />
		<br />
	</div>
</template>

<script>
import { titleMixin } from "../mixins/titleMixin";
import NosPartenaires from "@/components/NosPartenaires.vue";

export default {
	name: "LaSolution",
	mixins: [titleMixin],
	components: {
		NosPartenaires,
	},
	data() {
		return {
			title: "AutoParts Box - E-commerce, xRM pour grossiste et MRA pièce auto",
		};
	},
};
</script>

<style scoped>
#LaSolution {
	background: url("../assets/img/wallpapers/wallpaper-jumbotron.png") no-repeat;
	background-attachment: fixed;
	background-size: contain;
}
/* .solutionContainer {
  width: 1000px;
  margin: auto;
}*/
.solutionP {
	text-align: center;
	/* margin-bottom: 4rem; */
}
.solutionP h2 {
	font-size: 3.2rem;
	margin-bottom: 2rem;
}
.solutionP h2 span {
	color: var(--color-2);
}
.solutionP .fa-toolbox {
	font-size: 7rem;
	color: var(--color-2);
}
.solutionP p {
	font-size: 1.1rem;
	font-weight: 600;
	text-align: left;
}
.solutionP .bigRedBtn {
	display: block;
	width: fit-content;
	margin: 2rem auto 3rem;
	text-transform: uppercase;
}

.bandeau {
	height: 400px;
	margin: 2rem 0;
}
.bandeauBeige {
	background: url("../assets/img/wallpapers/wallpaper-jumbotron.png") no-repeat, #d7d7d788;
	background-attachment: fixed;
	background-size: contain;
}
.bandeau .solutionContainer {
	display: flex;
	height: 100%;
	position: relative;
}
.bandeau .solutionContainer > div {
	display: flex;
	align-items: center;
	justify-content: center;
}
.bandeau h3 {
	text-transform: uppercase;
	font-size: 1.7rem;
	text-align: center;
}
.bandeau h3 span {
	color: var(--color-2);
}
.bandeau img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	background: white;
}
.flex1 {
	flex: 1;
}
.flex2 {
	flex: 2;
}
.flex3 {
	flex: 3;
}

.scaleX {
	animation-name: scaleX;
}
@keyframes scaleX {
	from {
		transform: scaleX(0.1) scaleY(0.8);
	}
}

@media all and (max-width: 1300px) {
	.solutionContainer {
		width: 95%;
	}
	.solutionP {
		background: rgba(255, 255, 255, 0.7);
		padding: 0.5rem;
	}
}

@media all and (max-width: 1100px) {
	.solutionContainer {
		width: 95%;
	}
}

@media all and (max-width: 900px) {
	.flex1,
	.flex2,
	.flex3 {
		flex: unset;
	}
	.imgBox {
		width: 100%;
	}
	.imgBox img {
		object-fit: contain;
	}
	.bandeau {
		margin: 2rem 0;
	}
	.bandeauBeige {
		background: none;
	}
	.txtBox {
		position: absolute;
		top: 0;
		bottom: 0;
		background: rgba(255, 255, 255, 0.5);
		padding: 1rem 2rem;
	}
	.bandeau.bandeauBeige .txtBox {
		left: unset;
		right: 0;
	}
	.bandeau .txtBox {
		left: 0;
	}
}
@media all and (max-width: 650px) {
	#LaSolution {
		background: none;
	}
	.bandeau {
		height: auto;
		padding-bottom: 1rem;
		margin-bottom: 1rem;
	}
	.bandeauBeige {
		background: #d7d7d788;
	}
	.bandeau .solutionContainer {
		flex-direction: column;
	}
	.txtBox {
		position: relative;
	}
}
</style>
