<template>
	<div id="home">
		<div class="jumbotron">
			<!-- Image droite -->
			<div class="fullImg"></div>
			<!-- Bloc présentation AutoPartsBox.fr -->
			<div class="discover">
				<div class="content">
					<div class="title">
						<p class="discoverRed">LA SOLUTION ADAPTÉE</p>
						<p>AUX PROFESSIONNELS DE L'AUTOMOBILE</p>
					</div>

					<img src="@/assets/img/autopartsbox.png" alt="logo autopartsbox.fr" />
					<p>
						Vous êtes à la recherche d'un outil vous permettant de vendre en ligne vos pièces détachées, suivre vos
						clients tout en ayant un oeil sur les stocks ? Testez la démo et découvrez les nombreuses fonctionnalités
						qu'offre AutoPartsBox.fr !
					</p>
					<a class="demoLink" href="http://demo.autopartsbox.fr/demo.html" target="_blank" rel="noopener noreferrer">
						<i class="fas fa-caret-circle-right"></i>
						Testez la démo
					</a>
				</div>
			</div>
			<!-- Bloc services -->

			<div class="services">
				<div class="title"></div>
				<div class="container">
					<div class="content">
						<h2>fonctionnalités</h2>
						<p>
							Un seul point d'accès, ne perdez plus votre temps en saisissant la même information plusieurs fois à
							différents endroits ! Autopartsbox.fr est une solution tout en 1 qui vous offrent de nombreux
							avantages :
						</p>
					</div>
					<div class="avantages">
						<div class="aBlock">
							<i class="fal fa-shopping-cart"></i>
							<div class="aContent">
								<p>Ecommerce</p>
								<router-link to="/les-fonctionnalites#ecommerce">
									VOIR
									<i class="fas fa-caret-right"></i>
								</router-link>
							</div>
						</div>
						<div class="aBlock paiement">
							<i class="fal fa-shield-check"></i>
							<div class="aContent">
								<p>Paiement en ligne sécurisé</p>
								<router-link to="/les-fonctionnalites#paiementenligne">
									VOIR
									<i class="fas fa-caret-right"></i>
								</router-link>
							</div>
						</div>
						<div class="aBlock gestion">
							<i class="fal fa-abacus"></i>
							<div class="aContent">
								<p>Gestion</p>
								<router-link to="/les-fonctionnalites#gestion">
									VOIR
									<i class="fas fa-caret-right"></i>
								</router-link>
							</div>
						</div>
						<div class="aBlock">
							<i class="fal fa-sign"></i>
							<div class="aContent">
								<p>Publicité</p>
								<router-link to="/les-fonctionnalites#publicite">
									VOIR
									<i class="fas fa-caret-right"></i>
								</router-link>
							</div>
						</div>
						<div class="aBlock paiement">
							<i class="fal fa-cogs"></i>
							<div class="aContent">
								<p>Technique</p>
								<router-link to="/les-fonctionnalites#technique">
									VOIR
									<i class="fas fa-caret-right"></i>
								</router-link>
							</div>
						</div>
						<div class="aBlock gestion" @click="$parent.showLogPanel = !$parent.showLogPanel">
							<i class="fal fa-play-circle"></i>
							<div class="aContent">
								<p>Démo</p>
								<a href="javascript:;">
									VOIR
									<i class="fas fa-caret-right"></i>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Bloc vidéo -->
		<div class="videoContainer">
			<p>
				Pensée, créée et testée par des
				<span class="uppercase">professionnels de l'automobile</span>, découvrez
				<br />
				l'histoire et les services que propose autopartsbox!
			</p>
			<div class="videoContent">
				<iframe
					title="autopartsbox - solution adaptée aux professionnels de l'automobile"
					src="https://www.youtube.com/embed/hqe4LTVgetI"
					frameborder="0"
					id="myvideo"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
				></iframe>
			</div>
		</div>

		<NosPartenaires></NosPartenaires>

		<!-- Bloc En savoir plus -->
		<br />
		<br />
		<div class="savoir">
			<p class="title">En savoir plus ?</p>
			<p class="content">
				Vous souhaitez obtenir plus d’informations sur <br />l’adaptabilité de cette solution au sein de votre entreprise
				?
				<br />
				<br />
				N’hésitez pas à nous contacter, un professionnel <br />
				saura répondre à vos interrogations et pourra vous <br />proposer la solution la plus adaptée à votre projet.
			</p>
			<router-link to="/contact" class="bigRedBtn">CONTACTEZ-NOUS</router-link>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import { titleMixin } from "../mixins/titleMixin";
import NosPartenaires from "@/components/NosPartenaires.vue";

export default {
	name: "Home",
	mixins: [titleMixin],
	components: {
		NosPartenaires,
	},
	data() {
		return {
			title: "AutoParts Box - E-commerce, xRM pour grossiste et MRA pièce auto",
			alreadyLaunch: false,
		};
	},
	created() {
		window.addEventListener("scroll", this.launchVideo);
	},
	mounted() {},

	methods: {
		launchVideo() {
			// let vidEl = document.getElementById("myvideo");
			// console.log(vidEl.paused);
			// if (this.isInViewport(vidEl) && !this.alreadyLaunch) {
			//   vidEl.play();
			//   this.alreadyLaunch = true;
			// }
			let vidEl = document.getElementById("myvideo");
			let autoplay = "?autoplay=1";
			if (vidEl) {
				if (this.isInViewport(vidEl) && !this.alreadyLaunch) {
					vidEl.src = vidEl.src + autoplay;
					this.alreadyLaunch = true;
				}
			}
		},

		isInViewport(element) {
			const rect = element.getBoundingClientRect();
			return (
				rect.top >= 0 &&
				rect.left >= 0 &&
				rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
				rect.right <= (window.innerWidth || document.documentElement.clientWidth)
			);
		},
	},
};
</script>

<style scoped>
@import url("../css/responsif.css");

/* Jumbotron */
.jumbotron {
	position: relative;
	width: 100%;
}
.jumbotron .fullImg {
	position: absolute;
	width: 50%;
	background-image: url("../assets/img/carroussel/shutterstock_494860162.jpg");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	top: 0;
	right: 0;
	height: 124vh;
}
.jumbotron .discover {
	width: 50%;
	padding: 12rem 0;
	background-image: url("../assets/img/wallpapers/wallpaper-jumbotron_r1_c1.jpg");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	height: 57.5vh;
}
.jumbotron .discover .content {
	width: 50%;
	padding: 6rem 3rem;
	float: right;
}
.jumbotron .discover .content .title {
	display: none;
}
.jumbotron .discover .content img {
	width: 100%;
	margin-bottom: 1.5rem;
}
.jumbotron .discover .content p {
	font-size: 0.95rem;
	text-align: justify;
	margin-bottom: 1.5rem;
	line-height: 1.4rem;
	font-weight: 500;
}
.jumbotron .discover .content .demoLink {
	display: flex;
	align-items: center;
	color: var(--color-1);
	font-size: 1.7rem;
	font-weight: 800;
}
.jumbotron .discover .content .demoLink i {
	color: var(--color-2);
	font-size: 6rem;
	margin-right: 1rem;
}
.jumbotron .discover .content .demoLink:hover {
	color: var(--color-2);
	font-size: 1.8rem;
}
.jumbotron .services {
	width: 100%;
	background-color: var(--color-1);
	color: white;
	display: flex;
	justify-content: center;
}

.jumbotron .services .title {
	width: 5%;
	margin: 0.5rem -2rem 0.5rem 2rem;
	background-image: url("../assets/img/wallpapers/Fonctionnalites_r1_c1.png");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}
.jumbotron .services .container {
	padding: 3rem 8rem;
	width: 85%;
	margin-left: 8rem;
}
.jumbotron .services .container .content {
	width: 40%;
}
.jumbotron .services .container .content h2 {
	font-size: 4rem;
}
.jumbotron .services .container .content p {
	font-size: 1.1rem;
	font-weight: 500;
	margin-top: 1rem;
}
.jumbotron .services .avantages {
	margin-top: 3rem;
	display: flex;
	flex-wrap: wrap;
	font-family: Poppins, sans-serif;
	font-weight: bold;
}
.jumbotron .services .avantages .aBlock {
	display: flex;
	align-items: center;
	width: 33%;
	margin-top: 2rem;
}
.jumbotron .services .avantages .gestion {
	width: 15%;
}
.jumbotron .services .avantages .paiement {
	margin-right: 7rem;
}
.jumbotron .services .avantages .aBlock i {
	margin-right: 1rem;
}
.jumbotron .services .avantages .aBlock .fa-shopping-cart,
.jumbotron .services .avantages .aBlock .fa-shield-check,
.jumbotron .services .avantages .aBlock .fa-abacus {
	font-size: 3.4rem;
}
.jumbotron .services .avantages .aBlock .fa-sign,
.jumbotron .services .avantages .aBlock .fa-play-circle {
	font-size: 3.8rem;
}
.jumbotron .services .avantages .aBlock .fa-cogs {
	font-size: 2.7rem;
}
.jumbotron .services .avantages .aBlock .aContent p {
	font-size: 1.25rem;
}
.jumbotron .services .avantages .aBlock .aContent a {
	color: white;
	font-size: 0.8rem;
	display: flex;
	align-items: center;
}
.jumbotron .services .avantages .aBlock:hover a {
	/* font-size: 1rem; */
	color: var(--color-2);
	letter-spacing: 2px;
	font-size: 0.9rem;
}
.jumbotron .services .avantages .aBlock .aContent i {
	font-size: 1rem;
	color: var(--color-2);
	margin-left: 0.5rem;
}

/* Vidéo */
.videoContainer {
	padding: 3rem 1rem;
	width: 65%;
	margin: auto;
}
.videoContainer p {
	font-size: 1.3rem;
	font-weight: 600;
}
.videoContainer .uppercase {
	text-transform: uppercase;
	font-family: Exo-Black;
}
.videoContainer .videoContent {
	width: 100%;
	margin: auto;
	margin-top: 2rem;
}
.videoContainer .videoContent video {
	width: 100%;
	max-height: 90vh;
}
.videoContainer .videoContent iframe {
	width: 100%;
	height: 80vh;
}

/* Savoir */
.savoir {
	width: 50%;
	text-align: center;
	margin: auto;
	padding-bottom: 3rem;
}
.savoir .title {
	font-size: 1.8rem;
	margin-bottom: 0.5rem;
	font-weight: 700;
}
.savoir .content {
	margin-bottom: 2rem;
}

/* Duo */
.duo {
	display: flex;
	margin-top: 2rem;
}
.duo .red {
	color: var(--color-2);
}
.duo .title {
	text-align: center;
	margin-bottom: 2rem;
}
.duo .title p {
	font-size: 3rem;
	font-family: graphie, sans-serif;
	font-weight: 800;
	/* font-family: Exo-Black; */
}
.duo .bkgr-blue,
.duo .bkgr-red {
	width: 50%;
	padding: 1rem 0 2rem 0;
}
.duo .bkgr-blue .solution i,
.duo .bkgr-red .fonctionnalites i {
	font-size: 5rem;
}
.duo .bkgr-blue .solution ul,
.duo .bkgr-red .fonctionnalites ul {
	margin-left: 3rem;
	font-size: 0.9rem;
}
.duo .bkgr-blue .solution li,
.duo .bkgr-red .fonctionnalites li {
	min-height: 35px;
	margin-bottom: 4px;
}
.duo .bkgr-blue .solution,
.duo .bkgr-red .fonctionnalites {
	width: 70%;
}
.duo .bkgr-blue {
	background-color: #dde2e5;
}
.duo .bkgr-blue .solution {
	margin-left: 14rem;
}
.duo .bkgr-blue .solution .title {
	width: 75%;
}
.duo .bkgr-red .fonctionnalites {
	margin-left: 6rem;
}
.duo .bkgr-red .fonctionnalites .title {
	width: 89%;
}
.duo .bkgr-red {
	background-color: #fac7d1;
}
.duo li {
	list-style-type: none;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-weight: 600;
}
.duo li::before {
	content: "";
	display: inline-block;
	height: 30px;
	width: 40px;
	background-position: center;
	background-size: 25px;
	background-repeat: no-repeat;
	margin-right: 1rem;
}
/* .duo .bkgr-blue .solution li::before {
  background-image: url("../assets/img/listing/check-red.png");
}
.duo .bkgr-red .fonctionnalites li::before {
  background-image: url("../assets/img/listing/check-blue.png");
} */
</style>
