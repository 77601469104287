<template>
	<section id="cookiesPanel" class="CookiesPopUp" :class="showCookies ? null : 'cookiesBehind'">
		<div v-if="optionnalScripts">
			<slot></slot>
		</div>

		<section v-if="!choiceStep && showCookies">
			<span class="cookieTitle">Gérer mes cookies</span>

			<div class="textCookie">
				<p class="cookiesubtitle">Utilisation</p>
				<p>
					Notre site <b>{{ site }} </b> utilise des cookies pour l'analyse des audiences (Google Analytics) afin de nous
					permettre de mieux comprendre le comportement de nos visiteurs et d'améliorer l'expérience utilisateur.
				</p>

				<p class="cookiesubtitle">Conservation</p>
				<p>Nous conservons votre choix pendant 6 mois.</p>
			</div>

			<div class="buttonsCookie">
				<button class="perso" @click="choiceStep = true">
					Personnaliser mes choix
				</button>
				<!-- <button class="perso" @click="CookiesAreAccepted(false)">
					Tout refuser
				</button> -->
				<button class="accept" @click="CookiesAreAccepted(true)">
					Tout accepter
				</button>
			</div>
		</section>

		<section class="choice" v-if="choiceStep && showCookies">
			<div class="cookiesBloc">
				<div>
					<div class="choiceTitle">
						<span>Gérer mes cookies</span>
						<i>Personnalisation des choix</i>
						<br />

						<p>Nous conservons votre choix pendant 6 mois.</p>

						<div class="buttonsCookie">
							<button class="perso" @click="CookiesAreAccepted(false)">
								Tout refuser
							</button>
							<button class="accept" @click="CookiesAreAccepted(true)">
								Tout accepter
							</button>
						</div>
					</div>

					<article>
						<div>
							<input type="checkbox" id="cKsChkGA" v-model="optionnalScripts" />
							<label for="cKsChkGA">Amélioration de l'expérience utilisateur</label>
						</div>
						<div class="descCookie">
							Ceux-ci sont utilisés pour l'analyse des audiences grâce à
							<a
								href="https://marketingplatform.google.com/about/analytics/"
								target="_blank"
								rel="noreferer nofollow"
								title="Cliquez pour en savoir plus sur Google Analytics..."
								>Google Analytics</a
							>
							afin de nous permettre de mieux comprendre le comportement de nos visiteurs et d'améliorer
							l'expérience utilisateur.
						</div>
					</article>

					<div class="buttonsCookie">
						<button
							class="accept"
							@click="optionnalScripts ? CookiesAreAccepted(true) : CookiesAreAccepted(false)"
							style="float: right"
						>
							Valider mes choix
						</button>
					</div>
				</div>
			</div>
		</section>
	</section>
</template>

<script>
export default {
	name: "COOKIES",

	data: function() {
		return {
			choiceStep: false,
			optionnalScripts: false,
			// haveCookies: false
			showCookies: false,
		};
	},
	props: {
		color_one: {
			type: String,
			required: false,
			default: "#05CE7B",
		},
		color_two: {
			type: String,
			required: false,
			default: "#CE0558",
		},
		cookies_domain: {
			type: String,
		},
		site: {
			type: String,
		},
	},

	computed: {
		openClosePanel: {
			get() {
				return this.open_close;
			},
			set(val) {
				return this.$emit("update:open_close", val);
			},
		},
	},

	mounted() {
		this.$nextTick(() => {
			this.showCookies = true;
			if (navigator.cookieEnabled) {
				this.optionnalScripts = true;

				var etat = this.getCookie("acptCks");
				if (etat) {
					//   this.$el.style.display = "none";
					this.showCookies = false;
					if (etat == "all") {
						this.CookiesAreAccepted(true);
					} else if (etat == "no") {
						this.optionnalScripts = false;
						this.CookiesAreAccepted(false);
					}
				}
			}
			this.$el.style.setProperty("--cookie-one", this.color_one);
			this.$el.style.setProperty("--cookie-two", this.color_two);
		});
	},
	methods: {
		CookiesAreAccepted(param) {
			if (this.optionnalScripts && param === true) {
				this.setCookie("acptCks", "all", 182);
			} else {
				this.setCookie("acptCks", "no", 182);
				this.optionnalScripts = false;
			}
			this.$el.style.display = "none";
		},
		setCookie(cname, cvalue, numberOfDays) {
			var d = new Date();
			d.setTime(d.getTime() + numberOfDays * 24 * 60 * 60 * 1000);
			var expires = "expires=" + d.toUTCString();
			// document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=dartus.com";
			document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;secure;domain=" + this.cookies_domain;
		},

		getCookie(cname) {
			var name = cname + "=";
			var ca = document.cookie.split(";");
			for (var i = 0; i < ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) == " ") {
					c = c.substring(1);
				}
				if (c.indexOf(name) == 0) {
					return c.substring(name.length, c.length);
				}
			}
			return "";
		},
	},
};
</script>

<style>
.CookiesPopUp {
	background: rgba(100, 100, 100, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 4;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: 0.5s ease;
	font-family: Helvetica, sans-serif;
	color: #333;
}
@keyframes scaleOnOpen {
	from {
		transform: scale(0);
	}
}
.CookiesPopUp > section {
	width: 50%;
	/* height: 70%; */
	box-shadow: 2px 2px 8px 0px #777;
	background: #fefefe;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	animation: scaleOnOpen 0.5s ease;
	padding: 1%;
}
.cookiesBehind {
	z-index: -10;
	opacity: 0;
}
.CookiesPopUp a {
	text-decoration: underline;
	color: var(--cookie-one);
}

.CookiesPopUp .cookieTitle {
	margin: 0;
	margin-bottom: 0;
	text-transform: uppercase;
	color: var(--cookie-one);
	font-weight: bold;
	align-self: flex-start;
}
.CookiesPopUp .cookiesubtitle {
	margin: 0.5rem 0;
	font-weight: bold;
}
.closepopup {
	top: 1rem;
	right: 1rem;
	font-size: 2em;
}

.buttonsCookie {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
}
.buttonsCookie button {
	margin: 0 0.5rem;
	border: none;
	padding: 1rem;
	border-radius: 6px;
	text-transform: uppercase;
	cursor: pointer;

	transition: 0.5s ease;
	outline: none;
}

.buttonsCookie .perso {
	background: #ccc;
	color: black;
	border: 2px #ccc solid;
}
.buttonsCookie .refuse {
	background: #ccc;
	color: black;
	border: 2px #ccc solid;
}
.buttonsCookie .accept {
	border: 2px var(--cookie-one) solid;
	background: var(--cookie-one);
	color: var(--cookie-two);
}
.buttonsCookie button:hover {
	background: #fff;
}
.buttonsCookie .accept:hover {
	background: #fff;
	color: var(--cookie-one);
}

.textCookie {
	font-size: 1rem;
}
.cookiesBloc {
	display: flex;
	align-items: center;
}
.cookiesBloc span {
	margin-bottom: 0;
	text-transform: uppercase;
	color: var(--cookie-one);
	font-weight: bold;
}
.cookiesBloc p,
.cookiesBloc i {
	font-size: 1rem;
}

.cookiesBloc article {
	font-size: 0.8rem;
	margin: 1rem 0;
	background: #eee;
	padding: 8px 0;
}
.cookiesBloc article div:first-child {
	font-size: 1.2rem;
	color: var(--cookie-one);
	display: flex;
	align-items: center;
}
.cookiesBloc article input {
	width: 25px;
	height: 25px;
	margin-right: 10px;
}

.descCookie {
	margin-left: 30px;
}

@media all and (max-width: 1200px) {
	.CookiesPopUp > section {
		width: 70%;
	}
}

@media all and (max-width: 768px) {
	.CookiesPopUp > section {
		width: 90%;
		padding: 1rem 0.5rem;
	}
}

@media all and (max-width: 500px) {
	.buttonsCookie {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.buttonsCookie .perso {
		margin-bottom: 1rem;
	}
}
</style>
